@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



.animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }

  100% {
    transform: translateY(-10px);
  }
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

td {
  background: #fdfdff;
}

.admin-analytics tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top: 1px solid rgba(120, 81, 189, 0.2);
  border-bottom: 1px solid rgba(120, 81, 189, 0.2);
  border-left: 1px solid rgba(120, 81, 189, 0.2);
}
.admin-analytics tr td {
  border-top: 1px solid rgba(120, 81, 189, 0.2);
  border-bottom: 1px solid rgba(120, 81, 189, 0.2);
}

.admin-analytics tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top: 1px solid rgba(120, 81, 189, 0.2);
  border-bottom: 1px solid rgba(120, 81, 189, 0.2);
  border-right: 1px solid rgba(120, 81, 189, 0.2);
}

.admin-analytics tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.admin-analytics tr th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
